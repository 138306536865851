import React from 'react';
import Header from "../../components/Header/Header";
import './Form.style.scss';

export default function Payment() {
    document.title = "Form | Promote"

    // const ENVIRONMENT = 1

    // if (ENVIRONMENT === 1) {
    //     console.log = () => { }
    //     console.error = () => { }
    //     console.debug = () => { }
    // }

    // const { setValue, handleSubmit, formState: { error } } = useForm();


    return (
        <>
            <Header />
            <div className='container mx-auto' id='form'>
                <div className='flex justify-center items-center h-full'>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfM6XIVWab67aJ9lc8QWu4TozFrmQBFNOd9cHToO8noGH5Qmw/viewform?embedded=true" width="640" height="600" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
            </div>
        </>

    )
}
