import React from 'react';
import logowhite from '../../assets/images/logowhite.png';
import logo from '../../assets/images/logo1.png';
import { Link } from 'react-router-dom';
import './Header.style.scss';
import secureLocalStorage from "react-secure-storage";
import { useState, useEffect } from 'react';


export default function Header() {
    const role = secureLocalStorage.getItem('role')
    const role_web = secureLocalStorage.getItem("role_web");
    const [scrolled, setScrolled] = useState(false);
    
    const openMenu = (id) => {
        if(document.getElementById(id).classList.contains("hidden")){
            document.getElementById(id).classList.remove("hidden")
        } else {
            document.getElementById(id).classList.add("hidden")
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            // Only handle scroll for screens wider than 768px (desktop)
            if (window.innerWidth > 768) {
                if (window.scrollY > 25) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }
            }
        };

        // Only apply scroll behavior on the homepage (root "/")
        if (window.location.pathname === "/") {
            if (window.innerWidth > 768) {
                // Desktop: Set scrolled based on initial scroll position
                setScrolled(window.scrollY > 50);
                window.addEventListener('scroll', handleScroll);
            } else {
                // Mobile: Always set scrolled to true on load
                setScrolled(true);
            }

            // Cleanup event listener on unmount for desktop
            return () => {
                if (window.innerWidth > 768) {
                    window.removeEventListener('scroll', handleScroll);
                }
            };
        } else {
            // For non-homepage routes, set scrolled to true by default
            setScrolled(true);
        }
    }, []);


    
    function LoggedInMobile() {
        return (
            <li className='p-4 pl-0 block sm:hidden'>
                <a href="/user-info" className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black">My Profile</a>
            </li>
        )
    }
    
    function NotLoggedInMobile() {
        return (
            <li className='p-4 pl-0 block sm:hidden'>
                <a href="/login" className="text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 focus:outline-none ">Log in</a>
                {role_web == 1 ? 
                    <a href="/influencer/register" className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black">Get started</a>
                    :
                    <a href="/business/register" className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black">Get started</a>
                }
            </li>
        )
    }

    function LoggedIn() {
        return (
            <div className='sm:block hidden'>
                 <a href="/user-info" className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black">My Profile</a>
            </div>
        )
    }
    
    function NotLoggedIn() {
        return (
            <div className='sm:block hidden '>
                <a href="/login" className="text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 focus:outline-none ">Log in</a>
                {role_web == 1 ? 
                    <a href="/influencer/register" className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black">Get started</a>
                    :
                    <a href="/business/register" className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black">Get started</a>
                }
            </div>
        )
    }

    return (
        <div>
            <header className="sm:fixed relative z-[100] w-full top-0">
                <nav className={`sm:px-6 px-2 py-1 sm:py-4 lg:px-6 transition-all duration-300 shadow-lg ${scrolled ? 'bg-white block' : 'bg-transparent hidden'}`}>
                    <div className="container mx-auto flex flex-wrap justify-between items-center">
                        <div className="flex-1 flex justify-center">
                            <a href="/" className="flex items-center relative sm:left-[0px] left-[20px]">
                                <img src={scrolled ? logo : logowhite} className="logo" alt="Promote Logo" />
                            </a>
                        </div>
                        <div className="flex items-center lg:order-2">
                            <button
                                type="button"
                                className={`inline-flex items-center p-2 ml-1 text-sm rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600 ${scrolled ? 'text-primary' : 'text-white'}`}
                                aria-controls="mobile-menu-2"
                                aria-expanded="false"
                                onClick={(e) => {
                                    openMenu('mobile-menu-2');
                                }}
                            >
                                <span className="sr-only">Open main menu</span>
                                <svg
                                    className="w-8 h-8"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <svg
                                    className="hidden w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="flex justify-end w-full lg:w-auto lg:order-1 hidden" id="mobile-menu-2">
                        <div className="bg-white absolute top-0 right-0 h-screen sm:w-1/4 w-full">
                            <button className='absolute top-6 right-14' onClick={(e) => {
                                    openMenu('mobile-menu-2');
                                }}>
                                <i class="fa-solid fa-x"></i>
                            </button>
                            <ul className="flex flex-col justify-center items-center mt-4 font-medium gap-y-5 p-10">
                                <li className='w-full'>
                                    <a
                                        href="/"
                                        className="block py-2 lg:pr-4 lg:pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"
                                        aria-current="page"
                                    >
                                        <b>Home</b>
                                    </a>
                                </li>
                                <li className='w-full'>
                                    <a
                                        href="/case-study"
                                        className="block py-2 lg:pr-4 lg:pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"
                                    >
                                        <b>Case Studies</b>
                                    </a>
                                </li>
                                <li className='w-full'>
                                    <a
                                        href="/contact"
                                        className="block py-2 lg:pr-4 lg:pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"
                                    >
                                        <b>Contact</b>
                                    </a>
                                </li>
                                <li className="w-full">
                                    <a
                                        href="https://calendly.com/promote-io/30min"
                                        className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black w-full block"
                                        aria-current="page"
	    				data-event="button_click"
    					data-button-name="Book a Meeting"
    					data-category="Meeting"
                                    >
                                        <b>Book a Meeting</b>
                                    </a>
                                 </li>
                                 <li className="w-full">
                                    <button
                                        id="dropdownNavbarLink"
                                        data-dropdown-toggle="dropdownNavbar"
                                        className="flex items-center justify-between text-white bg-secondary hover:bg-primary focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black w-full"
                                    >
                                        <b>I am an Influencer</b>
                                        <svg
                                            className="w-2.5 h-2.5 ms-2.5"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 10 6"
                                        >
                                            <path
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="m1 1 4 4 4-4"
                                            />
                                        </svg>
                                    </button>
                                    <div
                                        id="dropdownNavbar"
                                        className="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-full relative"
                                    >
                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                                            <li>
                                                <a
                                                    href="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1707868800"
                                                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >
                                                    Apple App Store
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://play.google.com/store/apps/details?id=com.promoteMobileApp.PromoteApp"
                                                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >
                                                    Google Play Store
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            {/* <header className="fixed z-[100] w-full">
                <nav className="bg-transparent border-gray-200 border-b shadow px-6 lg:px-6 ">
                    <div className="container mx-auto flex flex-wrap justify-between items-center">
                        <a href="/" className="flex items-center">
                            <img src={logo} className="mr-3 logo" alt="Reach Logo" />
                        </a>
                        <div className="flex items-center lg:order-2  block">
                            <button type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false" onClick={(e)=> {openMenu('mobile-menu-2')}}>
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                            {(role == 1 || role == 2)  ? <LoggedIn /> : <NotLoggedIn />}

                            <div className='sm:block hidden '>
                                <a href="/login" className="text-gray-800 hover:bg-gray-50 focus:ring-2 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 focus:outline-none ">Log in</a>
                                <a href="/register" className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black">Get started</a>
                            </div>
                        </div>
                        <div className=" justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                            <ul className="flex flex-col justify-center lg:items-center mt-4 font-medium lg:flex-row lg:space-x-2 lg:mt-0">
                                <li>
                                    <a href="/" className="block py-2 lg:pr-4 lg:pl-3 text-gray-700  hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700" aria-current="page"><b>Home</b></a>
                                </li>
                                <li>
                                    <a href="/our-ethos" className="block py-2 pr-4 pl-3 text-gray-700  hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Our Ethos</b></a>
                                </li>
                                <li>
                                    <a href="/how-it-works" className="block py-2 pr-4 pl-3 text-gray-700  hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>How It Works</b></a>
                                </li>
                                <li>
                                    <a href="/case-study" className="block py-2 lg:pr-4 lg:pl-3 text-gray-700  hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Case Studies</b></a>
                                </li>
                               <li>
                                    <a href="/pricing-plan" className="block py-2 lg:pr-4 lg:pl-3 text-gray-700  hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Pricing</b></a>
                                </li>
                                <li>
                                    <a href="/contact" className="block py-2 lg:pr-4 lg:pl-3 text-gray-700  hover:bg-gray-50 lg:hover:bg-transparent border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Contact</b></a>
                                </li>
                                <li className='flex sm:flex-row flex-col gap-2'>
                                    <a href="https://calendly.com/promote-io/30min" className="text-white bg-primary hover:bg-black focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black" aria-current="page"><b>Book a Meeting</b></a>
                                    <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar"
                                    class="flex items-center justify-between text-white bg-secondary hover:bg-primary focus:ring-2 focus:ring-black font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-1 hover:bg-secondary focus:outline-none focus:ring-black"><b>I am an Influencer</b>
                                        <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                            fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="m1 1 4 4 4-4" />
                                        </svg>
                                    </button>
                                    <div id="dropdownNavbar"
                                        class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                        <ul class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                                            <li>
                                                <a href="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1707868800"
                                                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Apple App Store</a>
                                            </li>
                                            <li>
                                                <a href="https://play.google.com/store/apps/details?id=com.promoteMobileApp.PromoteApp"
                                                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Google Play Store</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="/blog" className="block py-2 lg:pr-4 lg:pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Blog</b></a>
                                </li>
                               
                                {/* <li>
                                    <a href="/blog" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700"><b>Blog</b></a>
                                </li> */}
                                {/* <li>
                                    <a href="https://promote-io.myshopify.com/products/ring-light" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-black lg:p-0 text-gray-400 lg:hover:text-primary hover:bg-gray-700 hover:text-primary lg:hover:bg-transparent border-gray-700" target="_blank"><b>Shop</b></a>
                                </li>
                                {(role == 1 || role == 2) ? <LoggedInMobile /> : <NotLoggedInMobile />}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header> */}
        </div>
    );
}
