import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from './pages/Login/Login';
import InfluencerRegister from './pages/Register/InfluencerRegister';
import StartupRegister from './pages/Register/StartupRegister';
import UpdateUserInfo from './pages/UserInformation/UpdateUserInfo';
import UploadPromotionPictures from './pages/UserInformation/UploadPromotionPictures';
import UpdateStartupInfo from './pages/UserInformation/UpdateStartupInfo';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { QueryParamProvider } from 'use-query-params';
import Search from './pages/Search/Search';
import HomePage from './pages/Home-old/Home';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import NewsPage from './pages/News/News';
import NewsById from './pages/News/NewsById';
import JobPost from './pages/Job/JobPost';
import Job from './pages/Job/Job';
import UserContext from './components/Context/UserContext';
import JobApply from './pages/Job/JobApply';
import JobApplications from './pages/Job/JobApplications';
import TokenService from './API/token.service';
import UserInfo from './pages/UserInformation/UserInfo';
import { userContext } from './components/Context/UserContext';
import UpdateSocial from './pages/UserInformation/UpdateSocial';
import Referrals from './pages/Referrals/Referrals';
import Logout from './pages/Logout/Logout';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import AppSocial from './pages/AppSocial/AppSocial';
import Payment from './pages/Payment/Payment';
import Pricing from './pages/Pricing/Pricing';
import InfluencerHome from './pages/InfluencerHome/InfluencerHome';
import StartupHome from './pages/Home/Home';
import OurEthos from './pages/OurEthos/OurEthos';
import CaseStudy from './pages/CaseStudy/CaseStudy';
import CaseStudyById from './pages/CaseStudy/CaseStudyById';
import WhatWeDo from './pages/WhatWeDo/WhatWeDo';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import Contact from './pages/Contact/Contact';
import UserById from './pages/UserInformation/UserById';
import Messages from './pages/Chat/Messages';
import JobUpdate from './pages/Job/JobUpdate';
import TaskCenter from './pages/TaskCenter/TaskCenter';
import secureLocalStorage from "react-secure-storage";
import MyJobs from './pages/Job/MyJobs';
import StartupById from './pages/UserInformation/StartupById';
import VerifiedEmail from './pages/VerifiedEmail/VerifiedEmail';
import { GoogleOAuthProvider } from '@react-oauth/google';
import InfluencerOnboarding from './pages/Onboarding/Influencer/Onboard';
import StartupOnboarding from './pages/Onboarding/Startup/Onboard';
import BespokeInfluencer from './pages/BespokeInfluencer/BespokeInfluencer';
import AdviceHub from './pages/AdviceHub/AdviceHub';
import AdviceById from './pages/AdviceHub/AdviceById';
import UpdateSocialTest from './pages/UserInformation/UpdateSocialTest';
import Form from './pages/Form/Form';

// if (process.env.ENVIRONMENT === 1) {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }

const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();


  const adaptedHistory = React.useMemo(
    () => ({
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

const user = TokenService.getUser();
const userAuth = user?.access_token
const root = createRoot(document.getElementById("root"));
root.render(
  
  // <React.StrictMode>
    <GoogleOAuthProvider clientId="7697836813-49i3gqk4pmuikpen93bhm29f2rlmmhon.apps.googleusercontent.com">
    <UserContext>
    <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
        <Routes>
            
            {/* Landing Pages */}   
            <Route path="/" element={<StartupHome />} />
            {/* <Route path="/influencer" element={<HomePage />} /> */}
            {/* <Route path="/business" element={<StartupHome />} /> */}
            {/* <Route path="/our-ethos" element={<OurEthos />} /> */}
            <Route path="/case-study" element={<CaseStudy />} />
            <Route path="/case-study/:title" element={<CaseStudyById />} />
            {/* <Route path="/how-it-works" element={<WhatWeDo />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<NewsPage />} />
            {/* <Route path="/article/:title" element={<NewsById />} />    */}
            {/* <Route path="/pricing-plan" element={<Pricing/>} />    */}
            <Route path="/privacy-policy" element={<Privacy/>} />   
            <Route path="/terms-and-conditions" element={<Terms/>} />   
            <Route path="/company-sign-up-form" element={<Form/>} />   

            {/* Dashboard Pages  */}
            {/* <Route path="/login" element={ <Login />} /> */}
            <Route path="/app-social" element={ <AppSocial />} />
            {/* <Route path="/influencer/register" element={<InfluencerRegister />} />
            <Route path="/business/register" element={<StartupRegister />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/influencer/onboarding" element={userAuth === null ?(<Navigate replace to={'/login/'} />) : <InfluencerOnboarding />} />
            <Route path="/business/onboarding" element={userAuth === null ?(<Navigate replace to={'/login/'} />) : <StartupOnboarding />} />   
            <Route path="/campaigns" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<Job />} />
            <Route path="/campaign-apply/:title" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<JobApply />} />
            <Route path="/campaign-update/:title" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<JobUpdate />} />
            <Route path="/campaign-applications" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<JobApplications />} />
            <Route path="/add-campaign" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<JobPost />} />
            <Route path="/my-campaigns" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<MyJobs />} />       
            <Route path="/show-user" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<UserById />} /> 
            <Route path="/show-startup" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<StartupById />} /> 
            <Route path="/verify-email" element={<VerifiedEmail />} />
            <Route path="/dashboard/pricing-plan" element={ userAuth === null ?(<Navigate replace to={'/login/'} />) : (<Payment />)} />
            <Route path="/dashboard/how-it-works" element={<HowItWorks />} /> */}
            <Route path="/password-reset" element={<PasswordReset />} />
            {/* <Route path="/update-social" element={ userAuth === null ?(<Navigate replace to={'/login/'} />) : <UpdateSocial/>} />
            <Route path="/update-user-profile" element={userAuth === null ?(<Navigate replace to={'/login/'} />) : <UpdateUserInfo/>} />
            <Route path="/upload-promotion-pictures" element={userAuth === null ?(<Navigate replace to={'/login/'}/>) : <UploadPromotionPictures />} />
            <Route path="/update-startup-profile" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<UpdateStartupInfo />}/>
            <Route path="/user-info" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<UserInfo />}/>
            <Route path="/referrals" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<Referrals />}/>
            <Route path="/get-influencer/:name" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<UserById />}/>
            <Route path="/search" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<Search />}/>
            <Route path="/messages" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<Messages/>} />
            <Route path="/task-center" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<TaskCenter/>} />            
            <Route path="/tailored-influencer" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<BespokeInfluencer/>} />            
            <Route path="/influencer-hub" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<AdviceHub/>} />            
            <Route path="/influencer-hub/:advice" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<AdviceById/>} />   
               Welcome Loay, 
            <Route path="/social-media-test" element={userAuth === null ?(<Navigate replace to={'/login/'} />) :<UpdateSocialTest/>} />          */}
        </Routes>
        </QueryParamProvider>
    </BrowserRouter>
    </UserContext>
    </GoogleOAuthProvider>
//  </React.StrictMode>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
